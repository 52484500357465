const State = [
        { value:'Andaman and Nicobar Islands',option:"Andaman and Nicobar Islands"},
        { value:'Andhra Pradesh',option:"Andhra Pradesh"},
        { value:'Arunachal Pradesh',option:"Arunachal Pradesh"},
        { value:'Assam',option:"Assam"},
        { value:'Bihar',option:"Bihar"},
        { value:'Chandigarh',option:"Chandigarh"},
        { value:'Chhattisgarh',option:"Chhattisgarh"},
        { value:'Dadra and Nagar Haveli',option:"Dadra and Nagar Haveli"},
        { value:'Daman and Diu',option:"Daman and Diu"},
        { value:'Delhi',option:"Delhi"},
        { value:'Goa',option:"Goa"},
        { value:'Gujarat',option:"Gujarat"},
        { value:'Haryana',option:"Haryana"},
        { value:'Himachal Pradesh',option:"Himachal Pradesh"},
        { value:'Jammu and Kashmir',option:"Jammu and Kashmir"},
        { value:'Jharkhand',option:"Jharkhand"},
        { value:'Karnataka',option:"Karnataka"},
        { value:'Kerala',option:"Kerala"},
        { value:'Ladakh',option:"Ladakh"},
        { value:'Lakshadweep',option:"Lakshadweep"},
        { value:'Madhya Pradesh',option:"Madhya Pradesh"},
        { value:'Maharashtra',option:"Maharashtra"},
        { value:'Manipur',option:"Manipur"},
        { value:'Meghalaya',option:"Meghalaya"},
        { value:'Mizoram',option:"Mizoram"},
        { value:'Nagaland',option:"Nagaland"},
        { value:'Odisha',option:"Odisha"},
        { value:'Puducherry',option:"Puducherry"},
        { value:'Punjab',option:"Punjab"},
        { value:'Rajasthan',option:"Rajasthan"},
        { value:'Sikkim',option:"Sikkim"},
        { value:'Tamil Nadu',option:"Tamil Nadu"},
        { value:'Telangana',option:"Telangana"},
        { value:'Tripura',option:"Tripura"},
        { value:'Uttar Pradesh',option:"Uttar Pradesh"},
        { value:'Uttarakhand',option:"Uttarakhand"},
        { value:'West Bengal',option:"West Bengal"}
    ];

export default State;


