import React, { useState, useEffect } from 'react';
import './Footer.scss';
import { SlLocationPin, SlEnvolope } from 'react-icons/sl';
import { SiWhatsapp } from "react-icons/si";
import { FaLanguage } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';


function Footer(props) {
    const today = new Date();
    const year = today.getFullYear();
    const [transCounter, setTransCounter] = useState(true);
    const navigate = useNavigate();
    const googleTranslateElementInit = () => {
        new window.google.translate.TranslateElement({
            pageLanguage: "en",
            autoDisplay: false,
        }, "google_translate_element");
    };

    useEffect(() => {
        if (transCounter == true) {
            var addScript = document.createElement("script");
            addScript.setAttribute("src", "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit");
            document.body.appendChild(addScript);
            window.googleTranslateElementInit = googleTranslateElementInit;
        }
        setTransCounter(false);
    }, []);

    return (
        <footer className="site-footer">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12 col-lg-6">
                        <h3>Downloads</h3>
                        <p className="mb-2">Coming soon...</p>
                        <p><a href={window.playstore} target="_blank" rel='noreferrer'>
                            <img src={require('../assets/images/playstore.svg').default} className="img-fluid playstore-btn" alt="Download Makkaddam | Play Store" />
                        </a></p>
                    </div>
                    <div className="col-xs-12 col-lg-6">
                        <h3>Communication</h3>
                        <ul className="footer-list">
                            <li><SiWhatsapp /> &nbsp;<a href={`https://api.whatsapp.com/send?phone=8141131984`} target='blank'>{window.site_phone}</a></li>
                            <li translate='no' className='d-flex'><SlLocationPin /> &nbsp;<span style={{marginLeft:5}}> "{window.company_name}" <br/>{window.site_address}</span></li>
                            <li><SlEnvolope /> &nbsp;<a href={"mailto:" + window.site_email}>{window.site_email}</a></li>
                        </ul>
                        <ul className='footer-network'>
                            <li>
                                <a href={window.facebook} target="_blank" rel='noreferrer'>
                                    <img src={require('../assets/images/facebook.svg').default} className="img-fluid d-block mx-auto" alt="Follow us on facebook" />
                                </a>
                            </li>
                            <li>
                                <a href={window.twitter} target="_blank" rel='noreferrer'>
                                    <img src={require('../assets/images/twitter.svg').default} className="img-fluid d-block mx-auto" alt="Follow us on twitter" />
                                </a>
                            </li>
                            <li>
                                <a href={window.instagram} target="_blank" rel='noreferrer'>
                                    <img src={require('../assets/images/instagram.svg').default} className="img-fluid d-block mx-auto" alt="Follow us on instagram" />
                                </a>
                            </li>
                        </ul>
                    </div>
                    
                </div>
            </div>
            <div className="footer-copy">
                <div className="container" translate='no'>
                    <div className='support_links'>
                        <span onClick={() => {navigate('/support')}}>Contact Us</span>
                        <span onClick={() => {navigate('/terms-and-conditions')}}>Terms And Conditions</span>
                        <span onClick={() => {navigate('/privacy-policy')}}>Privacy Policy</span>
                        <span onClick={() => {navigate('/cancellation')}}>Refunds & Cancellation</span>
                    </div>
                    
                </div>
            </div>
            <div className="copy-write text-center py-2" translate='no'>
            &copy; {year} {window.site_title}, All Rights Reserved.
            </div>
            <div className="translate">
                <FaLanguage style={{ color: 'white', fontSize: 30 }} />
                <div id="google_translate_element"></div>
            </div>
        </footer>
    );
}

export default Footer;