import React, { useEffect, useState, useRef } from 'react'
import Form from 'react-bootstrap/Form'
import InputBox, { Switch } from './InputBox'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './Register.scss';
import Modal from 'react-modal';
import moment from "moment";
import Select from 'react-select'
import * as htmlToImage from 'html-to-image';
import imgLogo from '../assets/images/logo.svg';
import { BiPhoneCall, BiMailSend, BiWorld, BiCurrentLocation, BiUser, BiPlusMedical } from 'react-icons/bi';
import State from './State';
import City from './City';
import { Dna } from 'react-loader-spinner'
import Button from 'react-bootstrap/Button';
import axios from "axios";
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
    border: 'none'
  },
  overlay: {
    backgroundColor: '#000000b0'
  },
};

const baseURL = "https://application.makkaddam.com/api/Signup";
// const baseURL = "http://192.168.29.245/makkaddam/api/Signup";
let axiosConfig = {
  headers: {
    'Content-Type': 'application/json',
    "x-api-key": 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.InVtaXlhamlwb2x5bWVyIg.BNYJfybnMIKeIpGYr5A3aGLiLT2wFCcfac7aElDpF-s',
    "token": 'makkaddam',
    "islogin": 'true',
    "id": 0
  }
};

function Register() {
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(moment().subtract(18, 'years')._d);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [states, setStates] = useState('');
  const [cities, setCities] = useState([]);
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  let monthIndex = (startDate.getMonth());
  let monthName = monthNames[monthIndex];
  let dayIndax = (startDate.getDay());
  let dayName = days[dayIndax];
  const [isLodding, setIsLodding] = useState(false);
  const [otherInput, setOtherInput] = useState(false);
  const [subOtherInput, setSubOtherInput] = useState(false);
  const currentYear = new Date().getFullYear();



  const domEl = useRef(null);
  const downloadImage = async (msg) => {
    
    
    setTimeout(async () => {
      const dataUrl = await htmlToImage.toJpeg(domEl.current);
      
      
      const link = document.createElement('a');
      link.download = 'ID-card.jpeg';
      link.href = dataUrl;
      link.click();
      setFromValue(initiaValues);
      navigate("/");
      setIsSubmit(false);
      setIsLodding(false);
      notify(msg);
    }, 1000);
  };

  const notify = (msg) => {
    toast(msg)
  };

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    //subtitle.style.color = '#f00';
  }

  const updateDate = (date) => {
    setIsOpen(false);
    setStartDate(date);
    const date_formate = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`
    setFromValue({ ...formValue, 'bod': date_formate, 'dtstr': date });
  }

  function closeModal() {
    setIsOpen(false);
  }
  const options = [
    { value: "1", label: "Excavation" },
    { value: "2", label: "Filling" },
    { value: "3", label: "Concrete" },
    { value: "4", label: "Centering (Carpenter)" },
    { value: "5", label: "steel bending" },
    { value: "6", label: "Masonry" },
    { value: "7", label: "Plaster" },
    { value: "8", label: "Tiles Work" },
    { value: "9", label: "Electrician" },
    { value: "10", label: "Plumber" },
    { value: "11", label: "Fabrication" },
    { value: "12", label: "Putty" },
    { value: "13", label: "POP" },
    { value: "14", label: "Painter" },
    { value: "15", label: "other" },
  ];

  const langOptions = [
    { value: "English", label: "English" },
    { value: "Assamese", label: "Assamese" },
    { value: "Bangla", label: "Bangla" },
    { value: "Bodo", label: "Bodo" },
    { value: "Dogri", label: "Dogri" },
    { value: "Gujarati", label: "Gujarati" },
    { value: "Hindi", label: "Hindi" },
    { value: "Kashmiri", label: "Kashmiri" },
    { value: "Kannada", label: "Kannada" },
    { value: "Konkani", label: "Konkani" },
    { value: "Maithili", label: "Maithili" },
    { value: "Malayalam", label: "Malayalam" },
    { value: "Manipuri", label: "Manipuri" },
    { value: "Marathi", label: "Marathi" },
    { value: "Nepali", label: "Nepali" },
    { value: "Oriya", label: "Oriya" },
    { value: "Punjabi", label: "Punjabi" },
    { value: "Tamil", label: "Tamil" },
    { value: "Telugu", label: "Telugu" },
    { value: "Santali", label: "Santali" },
    { value: "Sindhi", label: "Sindhi" },
    { value: "Urdu", label: "Urdu" }
  ];

  const initiaValues = { name: '', profilePic: '', eshram: false, state: '', city: '', phoneno: '', email: '', payment: '', bod: '', dtstr: '', experience: '', language: '', category: 'worker', subcategory: '', worktype: '', fmember: '0', work_type_other: '', sub_cate_other: '' };
  const [formValue, setFromValue] = useState(initiaValues);
  const [formError, setformError] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  var handleChange = (selectedOption) => {
    const onlyValues = selectedOption.map(d => d.value);
    const isOther = onlyValues.includes("15");
    if (isOther) {
      setOtherInput(true);
    } else {
      setOtherInput(false);
    }
    const worktypeData = onlyValues.toString();
    setFromValue({ ...formValue, 'worktype': worktypeData });
  };

  var handleLangChange = (selectedOption) => {
    const onlyValues = selectedOption.map(d => d.value);
    const worktypeData = onlyValues.toString();
    setFromValue({ ...formValue, 'language': worktypeData });
  };

  const formHandleChange = (e) => {

    const { name, value } = e.target;

    if (name === 'state') {
      setStates(value);
    }
    setFromValue({ ...formValue, [name]: value });
  }

  const subCategoryHandleChange = (e) => {
    const { name, value } = e.target;
    setFromValue({ ...formValue, [name]: value });
    if (value === 'other') {
      setSubOtherInput(true);
    } else {
      setSubOtherInput(false);
    }
  }

  useEffect(() => {

    setCities([]);
    setFromValue({ ...formValue, 'city': '' });
    City.map((e) =>
      states === e.state ? setCities(cities => [...cities, e]) : ''
    )
  }, [states]);

  useEffect(() => {
    if (isSubmit) {
      setformError(validate(formValue));
    }
  }, [formValue]);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const onSwitchAction = () => {
    setIsSwitchOn(!isSwitchOn);
    setFromValue({ ...formValue, 'eshram': !isSwitchOn });

  };

  const handleSubmit = (e) => {

    setIsSubmit(true);
    e.preventDefault();
    setformError(validate(formValue));

    if (Object.keys(formError).length === 0 && isSubmit) {

      setIsLodding(true);
      axios.post(`${baseURL}`, formValue, axiosConfig).then((response) => {
        const res = response.data;
     
        
        if (res.status == 200) {
          
          const done = downloadImage(res.msg);
        }
 
      }).catch(error => {

        const err = error.response;
        
        notify(err.data.msg);
        setIsLodding(false);
      });

    }
  }

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.name) {
      errors.name = "Name is required!";
    }
    if (!values.state) {
      errors.state = "State is required!";
    }
    if (!values.city) {
      errors.city = "City is required!";
    }
    if (!values.phoneno) {
      errors.phoneno = "Phone is required!";
    } else if (values.phoneno.length > 10 || values.phoneno.length < 10) {
      errors.phoneno = "Please enter valid phone no!";
    }

    
    if (!values.payment) {
      errors.payment = "Payment is required!";
    }

    if (!values.bod) {
      errors.bod = "Date of birth is required!";
    }
    if (!values.experience) {
      errors.experience = "Experience is required!";
    }

    if (!values.language) {
      errors.language = "Language is required!";
    }

    if (!values.subcategory) {
      errors.subcategory = "Sub-category is required!";
    }

    if (!values.worktype) {
      errors.worktype = "Work Type is required!";
    }

    if (!values.fmember) {
      errors.fmember = "Family member Type is required!";
    }

    if (otherInput && !values.work_type_other) {
      errors.work_type_other = 'Work Type is required!'
    }

    if (subOtherInput && !values.sub_cate_other) {
      errors.sub_cate_other = 'Sub Category is required!'
    }


    return errors;
  }

  const colourStyles = {
    control: styles => ({
      ...styles, fontSize: '1.125rem',
      border: formError.worktype ? '1px solid red' : '1px solid #dddddd',
      borderRadius: '1rem',
      padding: '0.1rem 0.5rem',
      borderWidth: '0.5px',

    })
  };

  const colourLangStyles = {
    control: styles => ({
      ...styles, fontSize: '1.125rem',
      border: formError.language ? '1px solid red' : '1px solid #dddddd',
      borderRadius: '1rem',
      padding: '0.1rem 0.5rem',
      borderWidth: '0.5px',
    })
  };

  const [file, setFile] = useState(null);
  const [fileError, setFileError] = useState(null);
  const handleImageChange = async (event) => {
    const image = URL.createObjectURL(event.target.files[0])
    const file = event.target.files[0]
    setFile(image);
    
    if (file.size < 3000000) {
      setFileError('')
      const base64 = await convertBase64(file)
      setFromValue({ ...formValue, 'profilePic': base64 });
    } else {
      setFileError('Please upload a file smaller than 3 MB')
      setFromValue({ ...formValue, 'profilePic': '' });
      setFile('');
    }

  }

  const handleFileInput = useRef(null);
  const handleClick = () => {
    handleFileInput.current.click();
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }


  return (
    <>
      <div className={!isLodding ? 'd-none loader-sec' : 'loader-sec'} >
        <Dna
          visible={true}
          height="80"
          width="80"
          ariaLabel="dna-loading"
          wrapperStyle={{}}
          wrapperClass="dna-wrapper"
        />
      </div>



      <section className='section-light pt-3' id='register' translate='no'>
        <div className='container'>
          <div className='row justify-content-center'>
            <h1 translate='no' className='text-center my-4 text-theme-color'>Register for Makkaddam</h1>
            <div className='col-12 col-sm-12 col-lg-6' translate='no'>
              <Form onSubmit={handleSubmit} action="#" >
                <InputBox validate={formError.name} title='Name' name='name' value={formValue.name} onChange={formHandleChange} type="name" placeholder="Enter Name" />
                <div className="register-upload">
                  <div className='circle'> {file ? <img src={file} onClick={handleClick} /> : <BiUser onClick={handleClick} style={{ fontSize: 100, color: '#b7b7b7', borderRadius: '50%', padding: 10, background: '#dddddd' }} />}</div>
                  <Form.Label className='mb-0 p-2 ms-2'>Upload profile photo</Form.Label>
                  <p className='err-tag'>{fileError}</p>
                </div>
                <div>
                  <label>
                    <input
                      style={{ display: 'none' }}
                      type='file'
                      accept="image/*"
                      capture="environment"
                      ref={handleFileInput}
                      onChange={handleImageChange}
                    />
                  </label>
                </div>
                <div>
                  <Form.Label className='mb-0 p-2 ms-2'>E-Shram Card - </Form.Label>
                  <Switch id="custom-switch" name="eshram" value={formValue.eshram} onChange={onSwitchAction} label="Yes" />
                  <Switch id="custom-switch" name="eshram" defaultChecked value={formValue.eshram} onChange={onSwitchAction} label="No" />
                </div>
                <div className='mb-3'>
                  <Form.Label className='mb-0 p-2 ms-2'>State</Form.Label>
                  <Form.Select className={formError.state ? 'error-bdr notranslate' : 'notranslate'} name="state" value={formValue.state} onChange={formHandleChange} aria-label="Default select example">
                    <option value=" ">--- Select State ---</option>
                    {State.map((d, index) => (
                      <option key={index} value={d.value}>{d.option}</option>
                    ))}
                  </Form.Select>
                  <p className='err-tag'>{formError.state}</p>
                </div>
                <div className='mb-3'>
                  <Form.Label className='mb-0 p-2 ms-2'>City</Form.Label>
                  <Form.Select className={formError.city ? 'error-bdr' : ''} name="city" value={formValue.city} onChange={formHandleChange} aria-label="Default select example">
                    <option value="">--- Select City ---</option>
                    {cities.map((d, index) => (
                      <option key={index} value={d.name}>{d.name}</option>
                    ))}
                  </Form.Select>
                  <p className='err-tag'>{formError.city}</p>
                </div>

                <InputBox validate={formError.phoneno} title='Phone No' type='number' name='phoneno' value={formValue.phoneno} onChange={formHandleChange} inputMode='numeric' placeholder="Enter Phone No." />
                <InputBox validate={formError.email} title='E-mail' type="email" name="email" value={formValue.email} onChange={formHandleChange} placeholder="Enter Email" />
                <InputBox validate={formError.payment} title='Full Day Payment (8 Hours)' name='payment' value={formValue.payment} onChange={formHandleChange} type="text" placeholder="Full Day Payment (8 Hours)" />

                <InputBox validate={formError.bod} title='Date of birth' onClick={() => setIsOpen(true)} value={formValue.bod} name='bod' type="text" readOnly placeholder={`${startDate.getDate()} / ${monthName} / ${startDate.getFullYear()}`} />
                <InputBox validate={formError.experience} title='Year of Experience' type="number" name="experience" value={formValue.experience} onChange={formHandleChange} inputMode='numeric' placeholder="Enter Experience" />

                <div className='mb-3'>
                  <Form.Label className='mb-0 p-2 ms-2'>Languages</Form.Label>
                  <Select isSearchable={false} name="language" styles={colourLangStyles} isMulti onChange={handleLangChange} options={langOptions} />
                  <p className='err-tag'>{formError.language}</p>
                </div>

                <InputBox title='Category' type="Text" name="category" placeholder="Enter Category" readOnly defaultValue={formValue.category} />
                <div className='mb-3'>
                  <Form.Label className='mb-0 p-2 ms-2'>Sub Category</Form.Label>
                  <Form.Select className={formError.subcategory ? 'error-bdr' : ''} name="subcategory" value={formValue.subcategory} onChange={subCategoryHandleChange} aria-label="Default select example">
                    <option value="">--- Select Sub Category ---</option>
                    <option value="Supervisor">Supervisor</option>
                    <option value="Forman">Forman</option>
                    <option value="Karigar (Mistri)">Karigar (Mistri)</option>
                    <option value="Helper">Helper</option>
                    <option value="other">other</option>
                  </Form.Select>
                  <p className='err-tag'>{formError.subcategory}</p>
                </div>

                {subOtherInput ? <InputBox validate={formError.sub_cate_other} title='Other(Sub Category)' name='sub_cate_other' value={formValue.sub_cate_other} onChange={formHandleChange} type="text" placeholder="Enter Your Sub Category" /> : ''}

                <div className='mb-3'>
                  <Form.Label className='mb-0 p-2 ms-2'>Work Type</Form.Label>
                  <Select isSearchable={false} name="worktype" styles={colourStyles} isMulti onChange={handleChange} options={options} />
                  <p className='err-tag'>{formError.worktype}</p>
                </div>

                {otherInput ? <InputBox validate={formError.work_type_other} title='Other' name='work_type_other' value={formValue.work_type_other} onChange={formHandleChange} type="text" placeholder="Enter Your Work Type" /> : ''}

                <InputBox validate={formError.fmember} title='Number of  Working  Family Member' name="fmember" defaultValue={formValue.fmember} onChange={formHandleChange} type='number' inputMode='numeric' placeholder="Number of  Working  Family Member" />
                <Button variant="light" className='custom-btn' type="submit">Save</Button>
              </Form>

              <div className="App">
                <div id="domEl" ref={domEl} className={isLodding ? 'user-id' : 'd-none'} >
                  <div className='img-header-blue'></div>
                  <div className='img-header-red'></div>
                  <div className='text-center mt-4'>
                    <img src={imgLogo} alt={window.site_title} className='img-fluid' />
                  </div>
                  <div className='text-center mt-2'>

                    {file ? <div className='img-card'> <BiPlusMedical className='postive' /> <div className='circle'><img src={file} onClick={handleClick} /> </div></div> : <div className='img-card'> <BiPlusMedical className='postive1' /> <BiUser style={{ fontSize: 100, color: '#b7b7b7', borderRadius: '50%', padding: 10, background: '#dddddd' }} /></div>}
                  </div>

                  <div className='text-center'>
                    <h5 className='title'>{formValue.name}</h5>
                    <h6 className='sub-title'>{formValue.subcategory === 'other' ? formValue.sub_cate_other : formValue.subcategory} ({formValue.experience}+ Years)</h6>
                    <h5 className='lang'>{formValue.language}</h5>
                  </div>

                  <div className='row justify-content-center'>
                    <div className='col-9 user-data mt-2'>
                      <p><BiPhoneCall /> +91 {formValue.phoneno}</p>
                      <p><BiMailSend /> {formValue.email}</p>
                      <p><BiWorld /> www.makkaddam.com</p>
                      <p><BiCurrentLocation /> {formValue.city},{formValue.state},INDIA</p>
                    </div>
                  </div>
                  <div className='text-center my-3'>
                    <h5 className='title'>कर्म करोति कल्याणम्</h5>
                  </div>
                  <div className='year'>{currentYear}</div>
                  <div className='img-header-red'></div>
                  <div className='img-header-blue'></div>
                </div>
              </div>

              <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                style={customStyles}
                contentLabel="Date Modal"
                ariaHideApp={false}
              >
                <div className='date-header'>
                  <p className='mb-1'>Select Date</p>
                  <h4><span className='dy-name'>{dayName}</span>, <span className='dy-month'>{monthName}</span> <span className='dy-day'>{`${startDate.getDate()} ${startDate.getFullYear()}`}</span></h4>
                </div>
                <DatePicker
                  selected={startDate}
                  dateFormat="MM/yyyy"
                  onChange={(date) => updateDate(date)}
                  inline
                  showYearDropdown
                  showMonthDropdown
                  minDate={moment().subtract(60, 'years')._d}
                  maxDate={moment().subtract(18, 'years')._d}
                  showDisabledMonthNavigation
                  useShortMonthInDropdown
                  dropdownMode="select"
                  calendarClassName="rasta-stripes"
                />
                <div className='text-end'>
                  <Button variant='link' className='link-btn' size="sm" onClick={closeModal}>Close</Button>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Register


