import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import InputBox from './InputBox'
import './Home.scss';
import { toast } from 'react-toastify';
import axios from "axios";


function Home() {
    const baseURL = "https://application.makkaddam.com/api/Signup/contact";
    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            "x-api-key": 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.InVtaXlhamlwb2x5bWVyIg.BNYJfybnMIKeIpGYr5A3aGLiLT2wFCcfac7aElDpF-s',
            "token": 'makkaddam',
            "islogin": 'true',
            "id": 0
        }
    };

    const initiaValues = { name: '', email: '', mobile: '', message: '' };
    const [formValue, setFromValue] = useState(initiaValues);
    const [formError, setformError] = useState({});
    const [submitted, setSubmitted] = useState(false);
    const handleSubmit = (e) => {
        setSubmitted(true);
        e.preventDefault();
        setformError(validate(formValue));
        if (Object.keys(formError).length === 0 && submitted) {
            // setIsLodding(true);
            
            axios.post(`${baseURL}`, formValue, axiosConfig).then((response) => {
                const res = response.data;
                if (res.status === 200) {
                    setFromValue(initiaValues);
                    setSubmitted(false);
                    notify(res.msg);
                }

            }).catch(error => {
                const err = error.response;
                notify(err.data.msg);
            });
        }
    };
    const notify = (msg) => {
        toast(msg)
    };
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        if (submitted) {
            setformError(validate(formValue));
        }
    }, [formValue]);

    const validate = (values) => {
        const errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if (!values.name) {
            errors.name = "Name is required!";
        }
        if (!values.message) {
            errors.message = "Message is required!";
        }
        if (!values.mobile) {
            errors.mobile = "Phone is required!";
        } else if (values.mobile.length > 10 || values.mobile.length < 10) {
            errors.mobile = "Please enter valid phone no!";
        }

        if (!values.email) {
            errors.email = "Email is required!";
        } else if (!regex.test(values.email)) {
            errors.email = "This is not valid email.";
        }



        return errors;
    }

    const formHandleChange = (e) => {
        const { name, value } = e.target;
        setFromValue({ ...formValue, [name]: value });
    }



    return (<>
        <section className="section-hero">
            <div className="hero-image hero-home" style={{ backgroundImage: "url(" + require('../assets/images/banner.png') + ")" }}></div>
            <div className='container h-100'>
                <div className='row align-items-center h-100'>
                    <div className='col-md-7 pb-5'>
                        <h1 className='hero-title'><span className='notranslate'>नमस्ते</span>, everyone. Thank you for coming today.</h1>
                        <p className='hero-lead'>Dear All, “MUKADAM” means who manage supply of various categories of labour force related to Construction Industry. Nowadays it is not fully assured and time bound supply and management. Also, the Job opportunity for these type of categories are not proper and systematic in the market.<br /> <br />
                        To resolve this, we are coming up with digitally platform and our prestigious and relentless innovative app <span className='notranslate'>“MAKKADDAM”</span> towards all of you. Which will help you to reduce your effort to manage the labour as We are coming up with the One Stop solution regarding the labour and Material resources in one platform.
                        </p>
                        <div className='row align-items-center'>
                            <div className='col-auto'>
                                <Link to="/register" className='btn btn-grad'>Register Now</Link>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-5'>
                        <img src={require('../assets/images/banner-app.png')} className='img-fluid d-block mx-auto' alt="Mobile App Banner" />
                    </div>
                </div>
            </div>
        </section>
        <section id="about" className='section-about section-light'>
            <div className='container'>
                <div className='row align-items-center'>
                    <div className='col-md-6'>
                        <div className='about-image'>
                            <img src={require('../assets/images/about.png')} className='img-fluid d-block mx-auto' alt="About App" />
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <h2 className='section-title'>We can assure you that…</h2>
                        <p>
                            This app will help you to getting the Authentic and Verified Managers, Supervisors, Skilled and Un–Skilled Labors/Workers for your work at single TAP. All over India as and when required.<br /><br />
                            We would like to tell you that even in this modern world a person who works physically on ground, do hard work, termed as labour or worker they never gets Priority which is not fair. But here in this platform to keep them in Priority you can give your valuable time and your service to others.
                        </p>
                    </div>
                </div>
            </div>
        </section>
        <section id="work" className='section-features section-light-x'>
            <div className='container'>
                <h2 className='section-title text-center'>What’s more in the App?</h2>
                <div className='row align-items-center mt-5'>
                    <div className='col-md-6'>
                        <img src={require('../assets/images/features-app.png')} className='img-fluid d-block mx-auto' alt="Features App" />
                    </div>
                    <div className='col-md-6'>
                        <div className="row align-items-center features-item">
                            <div className="col-auto">
                                <img src={require('../assets/images/icon-search.svg').default} className='img-fluid d-block mx-auto' alt="About App" />
                            </div>
                            <div className="col">
                                <h3>Easy Find Work / Worker</h3>
                            </div>
                        </div>
                        <div className="row align-items-center features-item">
                            <div className="col-auto">
                                <img src={require('../assets/images/icon-calendar.svg').default} className='img-fluid d-block mx-auto' alt="About App" />
                            </div>
                            <div className="col">
                                <h3>Easy Booking</h3>
                            </div>
                        </div>
                        <div className="row align-items-center features-item">
                            <div className="col-auto">
                                <img src={require('../assets/images/icon-support.svg').default} className='img-fluid d-block mx-auto' alt="About App" />
                            </div>
                            <div className="col">
                                <h3>Easy Payment</h3>
                            </div>
                        </div>
                        <div className="row align-items-center features-item">
                            <div className="col-auto">
                                <img src={require('../assets/images/icon-money.svg').default} className='img-fluid d-block mx-auto' alt="About App" />
                            </div>
                            <div className="col">
                                <h3>Great Support</h3>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
        <section id="service" className='section-light p-0'>
            <img src={require('../assets/images/app-works.jpg')} className='img-fluid d-block mx-auto' alt="App Works" />
        </section>
        <section className='section-light py-5'>
            <div className='container'>
                <div className='row align-items-center justify-content-center'>
                    <p className='text-center'>Interested Candidate for Construction Field now what are you waiting for, <span className='notranslate'>Makkaddam</span> Is ready to Provide you a systematic Platform for Earning Money on Just one Click So Register with us for Globle work platform….</p>
                    <div className="col-auto py-3">
                        <h2 className='section-title h1 m-0 text-blue'>Click here to join us</h2>
                    </div>
                    <div className='col-auto py-3'>
                        <Link to="/register" className='btn btn-grad'>Register Now</Link>
                    </div>
                </div>
            </div>
        </section>
        <section id="contact" className='section-light-x'>
            <div className='container'>
                <div className='row align-items-center'>
                    <div className='col-md-6'>

                        <form onSubmit={handleSubmit} className="form-contact">

                            <InputBox validate={formError.name} name='name' value={formValue.name} onChange={formHandleChange} type="text" placeholder="Your Name" />
                            <InputBox validate={formError.email} name='email' value={formValue.email} onChange={formHandleChange} type="email" placeholder="Your Email" />
                            <InputBox validate={formError.mobile} name='mobile' value={formValue.mobile} onChange={formHandleChange} type='number' inputMode='numeric' placeholder="Your Mobile" />
                            <div className="form-group">
                                <textarea validate={formError.message} placeholder="Your message" type="text" name="message" onChange={formHandleChange} value={formValue.message} className={formError.message ? "form-control error-bdr" : 'form-control'} rows="3" />
                                {formError.message ? <p className='err-tag'>{formError.message}</p> : ''}
                            </div>
                            <div className="form-group text-center">
                                <button className="btn btn-white" type="submit">Submit</button>
                            </div>
                        </form>
                    </div>
                    <div className='col-md-6'>
                        <img src={require('../assets/images/contact.png')} className='img-fluid d-block mx-auto my-4' alt="Contact Us" />
                    </div>
                </div>
            </div>
        </section>
    </>);
}

export default Home;