import React, {useState} from 'react';
import './Header.scss';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';
import { Container, Navbar, Nav, Offcanvas, NavbarBrand } from 'react-bootstrap';
import imgLogo from '../assets/images/logo.svg';
import close from '../assets/images/close.svg';

function Header() {
    const { hash } = useLocation();
    const [expanded, setExpanded] = useState(false);
    const isActive = (iHash) => {
        if (hash === iHash) {
            return true;
        }
    };

    const scrollWithOffset = (el, offset) => {
        const elementPosition = el.offsetTop - offset;
        window.scroll({
            top: elementPosition,
            left: 0,
            behavior: "smooth"
        });
    }
    
    
    return <>
        <header className="site-header">
            <Navbar key="lg" expand="lg" collapseOnSelect expanded={expanded}>
                <Container>
                    <NavbarBrand><Link to="/"><img src={imgLogo} alt={window.site_title} className='img-fluid' /></Link></NavbarBrand>
                    <Navbar.Toggle onClick={() => setExpanded(expanded ? false : true)} aria-controls={`offcanvasNavbar-expand-lg`} />
                    <Navbar.Offcanvas id={`offcanvasNavbar-expand-lg`} className="navbar-offcanvas" aria-labelledby={`offcanvasNavbarLabel-expand-lg`} placement="end">
                        <Offcanvas.Header>
                            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-lg`}>
                                <Link as={Link} onClick={() => setExpanded(false)} to="/"><img src={imgLogo} alt={window.site_title} className='img-fluid' /></Link>
                                <img src={close} onClick={() => setExpanded(false)} alt={window.site_title} className='close-icon' />
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <Nav className="justify-content-end flex-grow-1">
                                <Nav.Link onClick={() => setExpanded(false)} as={NavLink} className='nav-link' to='/' >Home</Nav.Link>
                                <NavHashLink onClick={() => setExpanded(false)} scroll={(el) => scrollWithOffset(el, 70)} className={() => isActive("#about") ? "nav-link active" : 'nav-link'} to="/home/#about">About</NavHashLink>
                                <NavHashLink onClick={() => setExpanded(false)} scroll={(el) => scrollWithOffset(el, 70)} className={() => isActive("#work") ? "nav-link active" : 'nav-link'} to="/home/#work">How to work</NavHashLink>
                                <NavHashLink onClick={() => setExpanded(false)} scroll={(el) => scrollWithOffset(el, 70)} className={() => isActive("#contact") ? "nav-link active" : 'nav-link'} to="/home/#contact">Contact</NavHashLink>
                                <Nav.Link onClick={() => setExpanded(false)} as={NavLink} className='nav-link' to='/register' >Register</Nav.Link>
                               

                            </Nav>
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
            </Navbar>
        </header>
    </>;
    }

    export default Header;