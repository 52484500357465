import React, {useEffect} from 'react'

function Cancellation() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <section className="section-products hero-small">
        <div className="container ">
          <div className="row d-flex align-items-center">
            <div className="col-12 col-lg-6">
              <div>
                <h1 className="hero-title">Cancellation & <br />Refund Policy</h1>
                <p className="hero-lead">Empowering Building Success with Skilled Workers</p>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">Home </li>
                  <li className="breadcrumb-item active"><span >Cancellation & Refund Policy</span></li>
                </ol>
              </div>
            </div>
            <div className="col-lg-6 col-12 text-center">
              <img src={require('../assets/images/refund.png')} className='img-fluid' />
            </div>
          </div>
        </div>
      </section>

      <section className="section-contact">
        <div className="container">
          <h3>1. Cancellation Policy for Service Seekers</h3>
          <p>As a user of our platform, you understand that while there are no statutory rights to cancel a booking for a Professional Service, you do have contractual entitlements under the following circumstances and terms</p>
            <h4>A. Cancellation within 48 to 24 Hours</h4>
            <p>If you cancel a booking within 48 to 24 hours of the scheduled date, a cancellation fee equivalent to 15% of the booking amount will be deducted.</p>
          

            <h4>B. Cancellation Beyond 48 Hours</h4>
            <p>For cancellations made beyond 48 hours of the scheduled date, a cancellation fee of 10% of the booking amount will be deducted.</p>

            <h4>C. No Cancellation within 24 Hours</h4>
            <p>Bookings cannot be canceled within 24 hours of the scheduled date. We encourage users to plan accordingly, as cancellations during this period are not permitted.</p>

            <h4>Refund Policy</h4>
            <p>Cancellation fees are applied to compensate for reserved space and resources. The remaining amount after deduction will be refunded to the user.</p>

            <h4>Cancellation Process</h4>
            <p>Users can initiate the cancellation process through the app’s booking section. Confirmation of cancellation will be sent via notification.</p>

            <h4>Exceptional Circumstances</h4>
            <p>In cases of unforeseen circumstances or emergencies, users may contact customer support to discuss their situation. We aim to provide flexibility and support during exceptional circumstances.</p>

            <h4>Additional Charges</h4>
            <p>Users will be charged the full-Service Fee under the following conditions:</p>
            <p> Cancelling a booking other than as permitted above. <br/>
              • Attempting to cancel a booking on or after the Service Time.<br/>
              • Failing to attend a booking at the Service Time and/or at the Designated Premises.<br/>
              • Failing to provide accurate information for your Worker to attend the Designated Premises.<br/>
              • Failing to provide accurate and complete contact details or personal information such as, but not limited to, full name, phone number, and address.
              </p>

            <p>A cancellation fee is levied to compensate the Worker, as it is unreasonable to expect them to provide a Professional Service at another booking with short or no notice.</p>

            <h4>Waiver of Cancellation Fee</h4>
            <p>Cancellation fees may be waived at our discretion in cases where users have been unable to cancel due to genuine reasons beyond their control. When cancellation fees are waived, we act as agents of the Worker who is the principal in supplying the Professional services.</p>
            
            <h3>2. Cancellation Policy for worker</h3>
            <p>Definition of Cancellation: A cancellation occurs when a worker on the platform withdraws from a job after accepting it.</p>
            <p>Cancellation Penalty: A worker who cancels a job will be subject to a penalty of either:</p>
            <p>A minimum penalty of 4% of the job's agreed-upon full payment with our service charges, or
              Up to the total amount deposited by the worker on the platform, whichever is higher.
            </p>


            <h4>Contact Information</h4>
            <p>If you have any questions or concerns about our Cancellation & Refund Policy, please contact us at support@makkaddam.com.</p>
            <p>Last updated: 03-08-2024</p>
            <p></p>
        </div>
      </section>
    </>
  )
}

export default Cancellation