import React, { useEffect } from 'react'

function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section className="section-products hero-small">
        <div className="container ">
          <div className="row d-flex align-items-center">
            <div className="col-12 col-lg-6">
              <div>
                <h1 className="hero-title">Privacy Policy</h1>
                <p className="hero-lead">Empowering Building Success with Skilled Workers</p>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">Home </li>
                  <li className="breadcrumb-item active"><span >Privacy Policy</span></li>
                </ol>
              </div>
            </div>
            <div className="col-lg-6 col-12 text-center">
              <img src={require('../assets/images/privacy-policy-top.png')} className='img-fluid' />
            </div>
          </div>
        </div>
      </section>
      <section className="section-contact">
        <div className="container">
          <h3>Introduction</h3>
          <p>Makkaddam ("we," "our," or "us") is committed to protecting the privacy and security of your personal information. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you use our website and mobile app (collectively referred to as the "Services").</p>
          <p>By accessing or using our Services, you consent to the practices described in this Privacy Policy. If you do not agree with our practices, please do not use our Services.</p>

          <h3>Information We Collect</h3>
          <h4>Personal Information</h4>
          <p>We may collect personal information that you provide to us when using our Services. This may include, but is not limited to:</p>
          <p>
            ● Your name <br />
            ● Contact information (e.g., email address, phone number)<br />
            ● Location data<br />
            ● Payment information<br />
            ● Booking history<br />
            ● Any other information you choose to provide<br />
          </p>

          <h3>Usage and Log Data</h3>
          <p>We may also collect usage and log data when you access our Services, including:</p>
          <p>
            ● Device information (e.g., device type, operating system)<br />
            ● IP address<br />
            ● Browser type<br />
            ● Pages you visit<br />
            ● Date and time of your visit<br />
            ● Usage patterns and preferences<br />
          </p>

          <h3>How We Use Your Information</h3>
          <p>We use your information for various purposes, including:</p>
          <p>
            ● To facilitate and manage bookings and reservations<br />
            ● To process payments for our services<br />
            ● To improve and personalize your experience<br />
            ● To respond to your inquiries and provide customer support<br />
            ● To send you important updates and notifications<br />
            ● To comply with legal obligations<br />
          </p>

          <h3>Disclosure of Your Information</h3>
          <p>We may share your information with third parties under the following circumstances:</p>
          <p>
          ● With service providers and business partners to facilitate the provision of our services<br />
          ● In response to legal requests or to comply with applicable laws and regulations<br />
          ● In the event of a merger, acquisition, or sale of assets, your information may be transferred as part of the transaction<br />
          ● With your consent or as otherwise disclosed at the time of collection<br />
          </p>

          <h3>Your Choices</h3>
          <p>You have the right to:</p>
          <p>
          ● Review and update your personal information by accessing your account settings<br />
          ● Request the deletion of your account and associated data, subject to legal requirements<br />
          ● Disable location services on your device, although this may impact certain features of our Services<br />
          </p>

          <h3>Security</h3>
          <p>We take reasonable measures to protect your information from unauthorized access, use, or disclosure. However, no method of transmission over the internet or electronic storage is entirely secure, and we cannot guarantee the absolute security of your data.</p>

          <h3>Children's Privacy</h3>
          <p>Our Services are not intended for children under the age of 18. We do not knowingly collect or maintain personal information from children.</p>
        
          <h3>Changes to this Privacy Policy</h3>
          <p>We may update this Privacy Policy from time to time to reflect changes in our practices or for legal reasons. We will notify you of any material changes through our Services or by other means.</p>
        
          <h3>Contact Us</h3>
          <p>If you have any questions, concerns, or requests regarding this Privacy Policy, please contact us at support@makkaddam.com.</p>
        
        </div>
      </section>
    </>
  )
}

export default PrivacyPolicy