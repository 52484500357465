// Global variables
window.site_title = "Makkaddam";
window.site_phone = "+91 81411 31984";
window.site_email = "support@makkaddam.com";
window.site_address = "Madhav, 4 Vrundavan Soc, Rajkot Postal Colony - Mavdi, Rajkot - 360004";
window.company_name = "MAKKADDAM SERVICE BUSINESS PRIVATE LIMITED";


// Application download links
window.playstore = "https://www.google.com/";
window.apple = "https://www.apple.com/";

// Social networks
window.facebook = "https://www.facebook.com/profile.php?id=100088980321386";
window.twitter = "https://twitter.com/makkaddam";
window.instagram = "https://www.instagram.com/makkaddam";