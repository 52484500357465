import React from 'react'
import Form from 'react-bootstrap/Form'
import './Register.scss';

function InputBox(props) {
    const err_calss = props.validate ? 'error-bdr' : '';
    return (
        <>

            <Form.Group className="mb-3" controlId="formGroupEmail">
                {props.title ? <Form.Label className='mb-0 p-2 ms-2'>{props.title}</Form.Label> : ''}
                <Form.Control className={err_calss} {...props} />
                {props.validate ? <p className='err-tag'>{props.validate}</p> : ''}
            </Form.Group>
        </>
    )
}

export default InputBox

export function Switch(props) {
    return (
        <Form.Check inline className='switch' type="radio" {...props} />
    );
}


