import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './Main.scss';
import './Config';
import Header from './components/Header';
import Home from './components/Home';
import Register from './components/Register';
import Footer from './components/Footer';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import Support from './components/Support';
import TermOfService from './components/TermOfService';
import PrivacyPolicy from './components/PrivacyPolicy';
import Cancellation from './components/Cancellation';

function App() {
    
    return (<>
        <BrowserRouter>
            <ToastContainer />
            <Header />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/home" element={<Home />} />
                <Route path="/register" element={<Register />} />
                <Route path="/support" element={<Support />} />
                <Route path="/terms-and-conditions" element={<TermOfService />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/cancellation" element={<Cancellation />} />

            </Routes>
            <Footer />
        </BrowserRouter>
    </>);
}

export default App;
