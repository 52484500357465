import React, { useState, useEffect } from 'react'
import InputBox from './InputBox'
import { toast } from 'react-toastify';
import axios from "axios";

function Support() {
  const baseURL = "https://app.makkaddam.com/api/Signup/contact";
  let axiosConfig = {
    headers: {
      'Content-Type': 'application/json',
      "x-api-key": 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.InVtaXlhamlwb2x5bWVyIg.BNYJfybnMIKeIpGYr5A3aGLiLT2wFCcfac7aElDpF-s',
      "token": 'makkaddam',
      "islogin": 'true',
      "id": 0
    }
  };

  const initiaValues = { name: '', email: '', mobile: '', message: '' };
  const [formValue, setFromValue] = useState(initiaValues);
  const [formError, setformError] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const handleSubmit = (e) => {
    setSubmitted(true);
    e.preventDefault();
    setformError(validate(formValue));
    if (Object.keys(formError).length === 0 && submitted) {
      // setIsLodding(true);

      axios.post(`${baseURL}`, formValue, axiosConfig).then((response) => {
        const res = response.data;
        if (res.status === 200) {
          setFromValue(initiaValues);
          setSubmitted(false);
          notify(res.msg);
        }

      }).catch(error => {
        const err = error.response;
        notify(err.data.msg);
      });
    }
  };
  const notify = (msg) => {
    toast(msg)
  };
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (submitted) {
      setformError(validate(formValue));
    }
  }, [formValue]);

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.name) {
      errors.name = "Name is required!";
    }
    if (!values.message) {
      errors.message = "Message is required!";
    }
    if (!values.mobile) {
      errors.mobile = "Phone is required!";
    } else if (values.mobile.length > 10 || values.mobile.length < 10) {
      errors.mobile = "Please enter valid phone no!";
    }

    if (!values.email) {
      errors.email = "Email is required!";
    } else if (!regex.test(values.email)) {
      errors.email = "This is not valid email.";
    }



    return errors;
  }

  const formHandleChange = (e) => {
    const { name, value } = e.target;
    setFromValue({ ...formValue, [name]: value });
  }
  return (
    <>
      <section className="section-products hero-small">
        <div className="container ">
          <div className="row d-flex align-items-center">
            <div className="col-12 col-lg-6">
              <div>
                <h1 className="hero-title">Contact Us</h1>
                <p className="hero-lead">Empowering Building Success with Skilled Workers</p>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">Home </li>
                  <li className="breadcrumb-item active"><span >Contact Us</span></li>
                </ol>
              </div>
            </div>
            <div className="col-lg-6 col-12 text-center">
              <img src={require('../assets/images/support.png')} className='img-fluid' />
            </div>
          </div>
        </div>
      </section>
      <section id="contact" className='section-light-x'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-md-6 col-12 align-self-center'>

              <form onSubmit={handleSubmit} className="form-contact">

                <InputBox validate={formError.name} name='name' value={formValue.name} onChange={formHandleChange} type="text" placeholder="Your Name" />
                <InputBox validate={formError.email} name='email' value={formValue.email} onChange={formHandleChange} type="email" placeholder="Your Email" />
                <InputBox validate={formError.mobile} name='mobile' value={formValue.mobile} onChange={formHandleChange} type='number' inputMode='numeric' placeholder="Your Mobile" />
                <div className="form-group">
                  <textarea validate={formError.message} placeholder="Your message" type="text" name="message" onChange={formHandleChange} value={formValue.message} className={formError.message ? "form-control error-bdr" : 'form-control'} rows="3" />
                  {formError.message ? <p className='err-tag'>{formError.message}</p> : ''}
                </div>
                <div className="form-group text-center">
                  <button className="btn btn-white" type="submit">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      <section className="section-contact ">
        <div className="container">
            <div className="row">
                <div className="col-md-12 ">
                    <div className="row row-flex flex-wrap flex-col">
                        
                        <div className="col-xs-12 col-sm-6 text-center">
                            <div className="contact-box">
                                <div className="cb-icon pt-3 pb-3">
                                    <img src={require('../assets/images/whats-app.png')} className='img-fluid' />
                                </div>
                                <h3 className="text-b-24">Whatsapp</h3>
                                <div className="cb-content">
                                    <p>Customer service: <br/><a href="https://api.whatsapp.com/send?phone=918141131984" target="_blank" rel="nofollow">+91 81411 31984</a></p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6 text-center">
                            <div className="contact-box">
                                <div className="cb-icon pt-3 pb-3">
                                    <img src={require('../assets/images/mail-icon.png')} className='img-fluid' />
                                </div>
                                <h3 className="text-b-24">Email Address</h3>
                                <div className="cb-content">
                                    <p>Customer service: <br/><a href="mailto:support@makkaddam.com" rel="nofollow">support@makkaddam.com</a></p>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default Support